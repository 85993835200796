import { ChakraProvider } from "@chakra-ui/react";
import Fonts from '../fonts'
import theme from '../theme';
import EnterPassword from "../components/EnterPassword";
import { useEffect, useState } from "react";

function AppWrapper({
  Component,
  pageProps,
  passwordCorrect,
  setPasswordCorrect,
}) {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const password = localStorage.getItem("foundPassword");

    const checkPassword = async (password) => {
      const response = await fetch("/api/check-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
        }),
      });

      const content = await response.json();

      if (content.verification) {
        setPasswordCorrect(true);
        setLoading(false);
      } else {
        setPasswordCorrect(false);
        setLoading(false);
      }
    };

    if (password) {
      checkPassword(password);
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <div>Loading</div>;

  return !passwordCorrect ? (
    <EnterPassword handleClose={setPasswordCorrect} />
  ) : (
    <Component {...pageProps} />
  );
}

function MyApp({ Component, pageProps }) {
  const [passwordCorrect, setPasswordCorrect] = useState(false);

  return (
    <ChakraProvider theme={theme}>
    <Fonts />
      {process.env.NEXT_PUBLIC_PASSWORD_PROTECTED ? <AppWrapper
        Component={Component}
        pageProps={pageProps}
        passwordCorrect={passwordCorrect}
        setPasswordCorrect={setPasswordCorrect}
      /> :
      <Component {...pageProps} />}
    </ChakraProvider>
  );
}

export default MyApp;
