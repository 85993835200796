// 1. import `extendTheme` function
import { extendTheme } from '@chakra-ui/react'

// 2. Add your color mode config -- dark mode
const config = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}
const breakpoints = {

  smPhone: '360px',
  mdPhone: '380px',
  lgPhone: '412px',
  xlPhone: '768px',
  sm: '320px',
  md: '768px',
  lg: '960px',
  xxl: '1280px',
  xl: '1281px',
  '1xl': '1680px',
  '2xl': '1800px',
  '3xl': '2500px',


}

// 3. extend the theme
const theme = extendTheme({ config, breakpoints })

export default theme